@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&display=swap');

@font-face {
  font-family: 'Geograph';
  src: url('/assets/fonts/Geograph-Regular.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Geograph';
  src: url('/assets/fonts/Geograph-Medium.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
}

// @font-face {
//   font-family: 'AvenirNextLTPro';
//   src: url('../assets/fonts/AvenirNextLTPro-Regular.woff2') format('woff2'),
//     url('../assets/fonts/AvenirNextLTPro-Regular.woff') format('woff');
//   font-weight: normal;
//   font-style: normal;
// }

// @font-face {
//   font-family: 'AvenirNextLTPro';
//   src: url('../assets/fonts/AvenirNextLTPro-Medium.woff2') format('woff2'),
//     url('../assets/fonts/AvenirNextLTPro-Medium.woff') format('woff');
//   font-weight: 500;
//   font-style: normal;
// }

// @font-face {
//   font-family: 'AvenirNextLTPro';
//   src: url('../assets/fonts/AvenirNextLTPro-Bold.woff2') format('woff2'),
//     url('../assets/fonts/AvenirNextLTPro-Bold.woff') format('woff');
//   font-weight: 700;
//   font-style: normal;
// }

// @font-face {
//   font-family: 'D-DIN';
//   src: url('../assets/fonts/D-DIN.otf') format('otf');
//   font-weight: normal;
//   font-style: normal;
// }

// @font-face {
//   font-family: 'applemyungjo';
//   src: url('../assets/fonts/applemyungjo-regular.ttf') format('ttf');
//   font-weight: normal;
//   font-style: normal;
// }

@import './bootstrap/bootstrap.scss';

@mixin transition() {
  transition: all 0.25s;
}

$primary: #17cbbc;

// Common
body {
  font-family: 'Geograph', sans-serif, arial;

  @include media-breakpoint-down(md) {
    font-size: 1rem;
  }
}

.text-lg-white {
  @include media-breakpoint-up(lg) {
    color: #fff;
  }
}

.btn-primary,
.btn-secondary {
  color: #fff;
  padding: 0.125em 2.5em;
  border: 4px solid transparent;
  border-radius: 0.625em;
  @include transition;
  &:hover {
    background-color: transparent;
  }
  &:focus,
  &:active {
    box-shadow: none;
  }
  &.btn-lg {
    font-size: 26px;
    font-weight: bold;
  }
}
.btn-primary {
  border-color: $primary;
  @include transition;
  &:hover {
    color: $secondary;
    border-color: $primary;
  }
  &.btn-border {
    background-color: transparent;
    color: $secondary;
    &:hover {
      color: #fff;
      background-color: $primary;
    }
  }
}
.btn-secondary {
  border-color: $secondary;
  border-radius: 0.5em;
  @include transition;
  &:hover {
    color: $secondary;
    border-color: $secondary;
  }
}
.color-primary {
  color: $primary !important;
}
.color-primarydeep {
  color: $primarydeep !important;
}
.color-secondary {
  color: $secondary !important;
}
a {
  &:hover {
    text-decoration: none;
  }
}
.page-heading {
  font-weight: bold;
  font-size: 3em;
}
.page-subheading {
  font-weight: bold;
  font-size: 2.6em;
}
.form-control {
  @include transition;
}
.form-check {
  font-size: 0.95em;
}
.form-check-input {
  position: absolute;
  opacity: 0;
  & + label {
    position: relative;
    cursor: pointer;
    padding: 0;
    left: -1.25rem;
    &:before {
      content: '';
      margin-right: 10px;
      display: inline-block;
      vertical-align: text-top;
      width: calc(1em + 6px);
      height: calc(1em + 6px);
      border: 4px solid $primary;
      border-radius: 5px;
      background-color: transparent;
      @include transition;
    }
  }
  &:checked + label:before {
    background-color: $primary;
  }
  &:focus + label:before {
    box-shadow: 0 0 0 0.2rem rgba(88, 192, 156, 0.25);
    border-color: #b5e3d3;
  }
}

// Components
.company-logos {
  display: flex;
  justify-content: space-evenly;
  div {
    width: 10%;
    height: 65px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-origin: content-box;
    padding: 5px;
  }
  @include media-breakpoint-down(sm) {
    div {
      width: 20%;
      height: 50px;
    }
  }
}

$maxBackgroundWidth: 1500;

// Q83-Website
.website-bg {
  position: absolute;
  background-color: #ffffff;
  min-height: 1000px;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: -2;
  .main-bg {
    left: 0;
    z-index: -1;
    background-color: #ffffff;
    position: absolute;
    width: 100%;
    height: 100%;
    max-width: #{$maxBackgroundWidth}px;
    @media (min-width: 1500px) {
      left: -webkit-calc(50% - #{($maxBackgroundWidth/2)}px);
      left: -moz-calc(50% - #{($maxBackgroundWidth/2)}px);
      left: calc(50% - #{($maxBackgroundWidth/2)}px);
    }
  }
  .bg-top,
  .bg-bottom {
    position: absolute;
    width: 100%;
    height: 100%;
    max-height: 550px;
    left: 0;
    top: 0;
    background-size: cover;
    background-position: 50% 100%;
    background-repeat: no-repeat;
    background-attachment: scroll;
    z-index: -1;
    @include media-breakpoint-down(xs) {
      max-height: 600px;
    }
  }
  .bg-bottom {
    top: auto;
    bottom: 0;
    background-position: 50% 0%;
  }
  &-bottom {
    .bg-top {
      display: none;
    }
    .bg-bottom {
      max-height: 400px;
    }
  }
}

.landing-bg {
  position: absolute;
  background-color: #ffffff;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: -2;
  background-image: url('https://media.q-83.com/site/bg-landing.jpg');
  background-image: url('https://media.q-83.com/site/2020/bg-landing.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  // @media (max-width: 1500px) {
  //   background-size: 1500px;
  // }
  opacity: 0.3;
}

$logoSize: 100;

.loading-bg {
  background-color: rgba(255, 255, 255, 0.6);
  width: 100%;
  height: 100%;
  min-height: 100vh;
  padding-top: -webkit-calc(50vh - #{($logoSize/2)}px);
  padding-top: -moz-calc(50vh - #{($logoSize/2)}px);
  padding-top: calc(50vh - #{($logoSize/2)}px);
}

// Layout
.site-layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  position: relative;

  &__header {
    padding-top: 80px;
  }
  &__body {
    flex-shrink: 0;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    > .container {
      display: flex;
      flex-grow: 1;
      height: 100%;
      flex-direction: column;
    }
  }
  &__footer {
    font-family: 'Open Sans', Arial, Helvetica, sans-serif;
    flex-shrink: 0;
    flex-grow: 0;
  }
}

// Header
.site-header {
  position: fixed;
  top: 0;
  width: 100%;
  left: 0;
  z-index: 10;
  background-color: #fff;
  transition: padding-bottom 0.3s;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.1);
  $header: &;
  > .container {
    display: flex;
    align-content: center;
    align-items: center;
    min-height: 80px;

    @include media-breakpoint-down(md) {
      align-items: center;
    }
  }
  &__logo {
    flex-grow: 0;
    flex-shrink: 0;
    margin: 0;
    img {
      height: 35px;
    }
  }

  &__buttons {
    margin-left: auto;
    flex-grow: 0;
    flex-shrink: 0;
    padding-top: 22px;
    @include media-breakpoint-down(md) {
      padding-top: 0;
    }
    .btn {
      margin-left: 1em;
      font-size: 16px;
      @include media-breakpoint-up(xl) {
        font-size: 18px;
      }
    }
  }
  // Navigation
  &__nav {
    flex-grow: 1;
    flex-shrink: 1;
  }
  .site-nav {
    display: flex;
    padding-left: 0;
    margin-bottom: 0;
    transition: height 0.3s;
    justify-content: flex-end;
    @include media-breakpoint-up(xl) {
      padding-left: 30px;
    }
    @include media-breakpoint-up(lg) {
      height: 28px;
      // &:hover {
      //   height: 120px;
      //   &:before {
      //     opacity: 1;
      //     visibility: visible;
      //   }
      // }
    }
    &__button {
      display: block;
      padding: 0 0.5em;
      position: relative;
      &:before {
        opacity: 1;
        display: block;
      }
      &.has-children {
        // &:hover {
        //   .site-nav__sub {
        //     opacity: 1;
        //     max-height: 120px;
        //   }
        // }
      }
    }

    a {
      font-size: 15px;
      font-weight: 500;
      color: $secondary;
      transition: $btn-transition;
      position: relative;
      cursor: pointer;
      display: inline-block;
      padding-bottom: 4px;
      &.active,
      &:hover {
        // color: $primary;
      }
      &:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 4px;
        background: $primary;
        top: 100%;
        left: 0;
        pointer-events: none;
        transform-origin: 100% 50%;
        transform: scale3d(0, 1, 1);
        transition: transform 0.3s;
      }
      &.active,
      &:hover {
        &:before {
          transform-origin: 0% 50%;
          transform: scale3d(1, 1, 1);
        }
      }
      @include media-breakpoint-up(xl) {
        font-size: 16px;
      }
    }
    &__sub {
      position: absolute;
      padding: 0.5em 0;
      overflow: hidden;
      opacity: 0;
      transition: opacity 0.3s;
      max-height: 0;
      li {
        padding: 0;
        white-space: nowrap;
      }
    }

    &:before {
      pointer-events: none;
      content: '';
      visibility: hidden;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(255, 255, 255, 0.5);
      z-index: 0;
      opacity: 0;
      transition: visibility 0s, opacity 0.3s;
    }
  }
  @include media-breakpoint-down(md) {
    &__logo {
      img {
        height: 50px;
      }
    }
    &__buttons {
      a {
        padding-left: 25px;
        padding-right: 25px;
      }
    }
    &__nav {
      display: none;
    }
    &__nav {
      opacity: 1;
      transition: opacity 0.3s, max-height 0.3s;
      position: absolute;
      width: 100%;
      overflow: hidden;
      max-height: 0;
      left: 0;
      top: 0;
      background-color: rgba(36, 44, 61, 0.9);
      padding-top: 100px;
      padding-bottom: 50px;
    }
    > .container {
      justify-content: space-between;
    }
    .btn-menu {
      font-size: 2em;
      color: #333;
      z-index: 1;
    }
    &.is-menu-open {
      #{$header}__nav {
        display: block;
        opacity: 1;
        max-height: 100vh;
        .site-nav {
          display: block;
          text-align: center;
        }
        a {
          color: #fff;
          font-size: 5vw;
          &:hover {
            color: $primary;
          }
        }
        .site-nav__sub {
          max-height: none;
          opacity: 1;
          position: relative;
          display: none;
          margin: 0 auto;
          text-align: center;
          padding-top: 0;
          a {
            font-size: 4vw;
          }
        }
      }
      .btn-menu {
        color: #fff;
      }
    }
  }
  @include media-breakpoint-down(md) {
    &__buttons {
      a {
        padding-left: 10px;
        padding-right: 10px;
      }
    }
  }
}

.content-footer {
  min-height: 200px;
}

.link-container {
  min-height: 40px;
}

// Footer
.site-footer {
  background-color: #333;
  .footer-links {
    font-size: 14px;
    padding-top: 25px;
    padding-bottom: 25px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &__container {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      align-items: flex-start;
    }
    &__item {
      padding: 10px;
      text-align: left;
      @include media-breakpoint-down(md) {
        padding: 4px;
        width: 100%;
      }
    }
    ul,
    li {
      display: inline-block;
      list-style: none;
      padding: 0;
    }
    ul {
      text-align: left;
      margin: 0;
    }
    li {
      margin-right: 1.2em;
      padding-top: 2px;
      padding-bottom: 2px;
    }

    button {
      color: white;
      font-weight: 500;
      &:hover {
        color: $primary;
      }
      background: none;
      border: none;
      padding: 0;
      outline: none;
    }
    .d-inline-block {
      // margin-left: 70px;
      @include media-breakpoint-down(xs) {
        width: 50%;
        margin-left: 0 !important;
        &:nth-child(2) {
          padding-left: 15px !important;
        }
      }
    }
    @include media-breakpoint-down(md) {
      flex-direction: column;
      > div {
        width: 100%;
        &:nth-child(1) {
          order: 2;
          margin-top: 30px;
          img {
            position: relative;
            left: -6px;
          }
        }
        &:nth-child(2) {
          order: 1;
        }
        &:nth-child(3) {
          order: 3;
          text-align: left !important;
          margin-left: 0 !important;
          margin-top: 30px;
          a {
            margin-left: 0;
            margin-right: 1rem;
          }
        }
      }
    }
  }
  .social-links {
    font-size: 2.8em;
    white-space: nowrap;
    line-height: 1;
    a {
      display: inline-block;
      margin-left: 1rem;
    }
    @include media-breakpoint-down(md) {
      align-self: flex-end;
    }
  }
}

// Copyright
.site-copyright {
  color: #fff;
  text-align: left;
  p {
    margin-bottom: 0;
    font-size: 12px;
    padding-top: 1em;
    padding-bottom: 1em;
  }
}

// Landing page
.page-landing {
  padding-top: 1em;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  &__middle {
    flex-grow: 1;
    display: flex;
    align-content: space-between;
    align-items: center;
    align-self: center;
    > br {
      flex-basis: 100%;
      display: block;
      height: 0;
    }
  }
  .logo {
    width: 120px;
    margin-top: 1em;
    margin-bottom: 3em;
    padding-top: 20px;
    @include media-breakpoint-up(sm) {
      width: 180px;
      padding-top: auto;
    }
    @include media-breakpoint-down(xs) {
      width: 40vw;
    }
  }
  hgroup {
    color: #fff;
    font-size: 2.4em;
    transform: translateX(1em);
    margin-bottom: 0.6em;
    h1 {
      font-size: 1.15em;
      font-weight: bold;
      div:first-child {
        transform: translateX(-2.75em);
      }
    }
    h3 {
      font-size: 0.65em;
      font-weight: 200;
    }
  }
  .btn-primary {
    font-weight: 200;
  }
  .landing-buttons {
    margin-bottom: 70px;
    .btn-primary {
      font-size: 1.2em;
      padding: 0.3em 1.2em 0.1em;
      margin-bottom: 0.6em;
    }
  }
  @include media-breakpoint-down(md) {
    hgroup {
      transform: translateX(0);
      font-size: 6vw;
      h1 {
        div:first-child {
          transform: translateX(0);
        }
      }
    }
  }
  @include media-breakpoint-down(sm) {
    .btn-primary {
      font-size: 1.15em;
    }
  }
}

// Home
.page-home {
  &__banner {
    background-image: url('/assets/images/q-83-header.svg');
    background-size: auto 100%;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    height: 790px;
    @media screen and (max-width: 1400px) {
      height: 600px;
    }
    @media screen and (max-width: 1200px) {
      height: 600px;
      background-position: 50% 100px;
    }
    @media screen and (max-width: 991px) {
      background-image: url('/assets/images/q-83-header-mobile.svg');
      height: auto;
      padding-bottom: 146%;
      background-size: 100% auto;
      background-position: 30px 100%;
      margin-bottom: 80px;
    }
    &-bg {
      margin-top: 80px;
      // position: relative;
      hgroup {
        // position: absolute;
        top: 0;
        left: 0;
        width: 55%;
        padding-top: 30px;
        h2 {
          font-size: 35px;
          font-weight: 400;
          line-height: 1.25;
          span {
            position: relative;
            z-index: 0;
            display: inline-block;
            font-weight: bold;
            &:before {
              content: '';
              position: absolute;
              bottom: 0;
              width: 100%;
              height: 0.5em;
              background-color: #ffeee8;
              z-index: -1;
            }
          }
        }
        h3 {
          margin-top: 1.5em;
          font-size: 26px;
          font-weight: 400;
        }
        @media screen and (max-width: 991px) {
          width: auto;
          &:after {
            content: '';
            position: absolute;
            left: 0;
            width: 100%;
            padding-top: 35.6%;
            background-image: url('/assets/images/q83-home-mobile-line.jpg');
            display: block;
            background-position: 50% 50%;
            z-index: -1;
            background-repeat: no-repeat;
            background-size: cover;
            margin-top: -30px;
          }
        }
      }
    }
  }
  &__stats {
    background-color: #333;
    color: #fff;
    font-size: 18px;
    padding-top: 100px;
    padding-bottom: 100px;
    .stats-container {
      display: flex;
      justify-content: space-between;
      @media screen and (max-width: 991px) {
        display: block;
        padding-left: 20px;
        div {
          margin: 10px;
        }
      }
    }
    .number {
      font-size: 50px;
      font-weight: 700;
      display: block;
      line-height: 1;
    }
    .list-stats {
      > div {
        width: 20%;
        text-align: center;
      }
      @media screen and (max-width: 991px) {
        flex-wrap: wrap;
        > div {
          width: 33%;
          margin-bottom: 30px;
        }
      }
      @media screen and (max-width: 767px) {
        > div {
          width: 50%;
        }
      }
    }
  }
  &__clients {
    h3 {
      font-size: 20px;
      font-weight: 400;
      margin: 100px 0 50px 0;
      text-align: center;
    }
  }
  &__products {
    margin-top: 100px;
    font-size: 18px;
    h3 {
      font-size: 30px;
      color: $primary;
    }
    h4 {
      margin-bottom: 30px;
    }
    h6 {
      color: $primary;
    }
    p {
      font-size: 18px;
      margin-bottom: 2em;
    }
    .img-product {
      max-width: 100%;
    }
    .link-learn-more {
      text-decoration: underline;
    }
    .link-video {
      display: flex;
      align-items: center;
      svg {
        font-size: 1.7em;
        margin-right: 10px;
      }
    }
    .products__kitly {
      .kitly-logo {
        height: 57px;
        margin-bottom: 20px;
      }
      @include media-breakpoint-down(sm) {
        text-align: center;
      }
      margin-bottom: 150px;
      .link-video {
        color: $primary;
      }
      .link-apps {
        img {
          height: 40px;
          margin-right: 10px;
        }
      }
      .header {
        h6 {
          font-size: 30px;
          margin-bottom: 24px;
          font-weight: 400;
        }
        img {
          width: 93px;
          @include media-breakpoint-down(sm) {
            margin: 30px 0;
          }
        }
      }
      @include media-breakpoint-down(sm) {
        position: relative;
        padding-bottom: 150px;
        .row > div {
          position: initial;
        }
        .link-video,
        .link-apps {
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          bottom: 0;
          width: auto;
          text-align: center;
          display: inline-flex;
          margin-left: auto;
          margin-right: auto;
        }
        .link-video {
          margin-bottom: 80px;
        }
        .link-apps {
          margin-bottom: 0px;
        }
      }
    }
    .products__kitly-business {
      margin-bottom: 80px;
      .link-video {
        color: $primary;
      }
      @include media-breakpoint-down(sm) {
        text-align: center;
      }
      .kb-logo {
        height: 42px;
        margin-bottom: 20px;
      }
      @include media-breakpoint-down(sm) {
        position: relative;
        padding-bottom: 50px;
        .row > div {
          position: initial;
        }
        .link-video {
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          top: 100%;
          width: auto;
          text-align: center;
          display: inline-flex;
          margin-left: auto;
          margin-right: auto;
        }
        .link-video {
          margin-top: 0px;
        }
      }
    }
  }
  &__testimonials {
    text-align: center;
    margin-top: 100px;
    h5 {
      font-size: 16px;
      letter-spacing: 1.78px;
      margin-bottom: 30px;
      svg {
        font-size: 60px;
        margin-bottom: 50px;
      }
    }
    .testimonials__icon {
      display: inline-block;
      width: 114px;
      height: 106px;
      background-repeat: no-repeat;
      background-image: url('/assets/images/icon-testimonials.png');
      margin-bottom: 30px;
    }
    .testimonials__text {
      font-size: 25px;
      max-width: 100%;
      width: 610px;
      margin: 15px auto;
    }
    .testimonials__author {
      font-size: 18px;
      color: $primary;
      position: relative;
      padding-bottom: 1em;
      margin-top: 30px;
      margin-bottom: 100px;
      &:after {
        content: '';
        position: absolute;
        width: 128px;
        height: 4px;
        background-color: $primary;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
}

// Template Content
.template-content {
  max-width: 100%;
  overflow: visible;
  .long-heading {
    @include media-breakpoint-up(lg) {
      width: 575px;
      margin-left: -50px;
    }
  }
  .page-heading-container {
    @include media-breakpoint-down(lg) {
      min-height: 278px;
    }
    @include media-breakpoint-down(sm) {
      min-height: 320px;
    }
  }
  .page-heading {
    p {
      text-align: right;
    }
    @include media-breakpoint-down(md) {
      text-align: center;
      p {
        text-align: center;
      }
    }
    @include media-breakpoint-down(xs) {
      padding-left: 0 !important;
      padding-right: 0 !important;
      font-size: 2em;
      margin-top: 0 !important;
      margin-bottom: 1rem !important;
    }
  }
  .page-subheading {
    max-width: 100%;
    text-align: center;
    @include media-breakpoint-down(md) {
      font-size: 2.2em;
    }
    @include media-breakpoint-down(xs) {
      padding-left: 0 !important;
      padding-right: 0 !important;
      font-size: 2em;
    }
  }
  .img-fluid {
    max-width: 510px;
    width: 100%;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}

.list-campaigns {
  height: 600px;
  @include media-breakpoint-up(md) {
    height: 650px;
  }
  @include media-breakpoint-up(sm) {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
  margin-left: -15px;
  margin-right: -15px;
  overflow: hidden;
  position: relative;
  .campaign-item {
    position: absolute;
    left: 50%;
    top: 0;
    @include transition;
    z-index: 0;
  }
}

.btn-nav {
  position: absolute;
  z-index: 3;
  top: 50%;
  transform: translateY(-50%);
  opacity: 0.2;
  font-size: 40px;
  padding: 8px 0 0 0;
  transition: opacity 0.3s;
  &.btn-prev {
    left: 5px;
  }
  &.btn-next {
    right: 5px;
  }
  &:hover {
    opacity: 0.5;
  }
}

.posts-slider {
  margin-bottom: 60px;
  .btn-nav {
    &.btn-prev {
      left: -40px;
    }
    &.btn-next {
      right: -40px;
    }
  }
  &__card {
    padding: 20px;
    .header {
      display: flex;
      align-items: center;
      overflow: hidden;
    }
    .avatar {
      width: 50px;
      border-radius: 25px;
      margin-right: 8px;
    }
    a.title {
      font-weight: 500;
      font-size: 1.1em;
      line-height: 1.4em;
      height: 2.8em;
      margin: 6px;
      overflow: hidden;
    }
    .link {
      font-weight: 500;
    }
    .preview {
      margin-top: 8px;
      height: 4.8em;
      overflow: hidden;
      line-height: 1.2em;
      p {
        margin: 0;
      }
      li p {
        margin: 0;
      }
      h1,
      h2,
      h3,
      h4 {
        font-size: 1em !important;
        margin: 0;
      }
    }
  }
}

.campaign-item {
  width: 335px;
  border: 4px solid $secondary;
  &__img {
    // padding: 1em;
    min-height: 270px;
    position: relative;
    border-bottom: 2px solid $secondary;
    overflow: hidden;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    .card-img-top {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translateX(-50%) translateY(-50%);
      max-width: calc(100%);
    }
  }
  .card-text {
    font-size: 0.8em;
    max-height: 160px;
    overflow: hidden;
  }
  .btn {
    font-size: 0.8em;
    padding: 0.15em 1em;
    margin-bottom: 5px;
    border-radius: 5px;
    border-width: 0;
    &.btn-info {
      margin-right: 5px;
      width: calc(100% - 5px);
    }
    &.btn-gray {
      color: #fff;
      background-color: $gray-600;
    }
  }
}

// Influencer New
.influencer-content {
  display: flex;
  width: 100%;
  flex-grow: 1;
  justify-content: center;
  position: relative;
  &__1st,
  &__3rd {
    width: 28%;
    text-align: center;
  }
  &__2nd {
    width: 44%;
    position: relative;
  }
  &__phone {
    position: relative;
    width: 100%;
    // &-top,
    // &-left,
    // &-right,
    // &-bottom {
    //   background-size: contain;
    //   background-repeat: no-repeat;
    // }
    &__top {
      position: absolute;
      top: 0;
      left: 0;
      background-image: url('https://media.q-83.com/site/2020/iphone_x.png');
      background-size: cover;
      background-repeat: no-repeat;
      height: 100%;
      width: 100%;
      z-index: 1;
      pointer-events: none;
    }
    &-top {
      width: 100%;
      padding-top: 13.333333%;
      position: relative;
      z-index: 1;
    }
    &-left {
      width: 4%;
      padding-top: 172.333333%;
      float: left;
    }
    &-right {
      width: 4%;
      padding-top: 172.333333%;
      float: left;
    }
    &-wrapper {
      width: 92%;
      padding-top: 191%;
      float: left;
      overflow: hidden;
      position: relative;
      margin-top: -9%;
      margin-bottom: -5%;
      border-radius: 20px;
    }
    &-center {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 50px;
      overflow-y: scroll;
    }
    &-bottom {
      clear: both;
      width: 100%;
      padding-top: 13.416666%;
      position: relative;
      z-index: 1;
    }
  }
  &__image {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
  &__block {
    margin-top: 0px;
    margin-bottom: 30px;
    img {
      height: 20px;
      display: inline-block;
      margin-bottom: 10px;
    }
    h5 {
      color: $primary;
    }
    p {
      font-size: 1em;
      min-height: 50px;
    }
  }

  @include media-breakpoint-down(lg) {
    flex-direction: column;
    &__1st,
    &__2nd,
    &__3rd {
      width: 100%;
    }
    &__2nd,
    &__3rd {
      margin-bottom: 60px;
    }
  }
  @include media-breakpoint-up(xl) {
    &__1st {
      text-align: right;
      padding-right: 30px;
    }
    &__3rd {
      text-align: left;
      padding-left: 30px;
    }
    &__block {
      margin-top: 70px;
      img {
        height: 30px;
      }
      p {
        font-size: 0.7em;
        min-height: 90px;
      }
    }
  }
}

// Page Our Team
.page-our-team {
  .page-heading {
    .text-secondary {
      display: inline-block;
      position: relative;
      transform: translateX(1.75em);
    }
  }
}
.list-our-team {
  .member {
    $member: '.member';
    position: relative;
    @include transition;
    &__img {
      position: relative;
      background-size: cover;
      padding-top: 100%;
      &:before {
        position: absolute;
        z-index: 0;
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background-color: rgba(0, 0, 0, 0);
        @include transition;
      }
    }
    &__tile {
      display: block;
    }
    &__details {
      display: none;
    }
    &__title {
      opacity: 0;
      position: absolute;
      bottom: 20px;
      left: 15px;
      color: #fff;
      z-index: 1;
      @include transition;
      i {
        font-weight: 300;
        font-size: 0.9em;
      }
    }
    &__info {
      position: absolute;
      z-index: 1;
      width: 0%;
      height: 100%;
      overflow: auto;
      opacity: 0;
      top: 0;
      left: 100%;
      background-color: #87d2b9;
      color: #fff;
      display: flex;
      flex-direction: column;
      padding: 25px 0px 25px 0px;
      @include transition;
      #{$member}__description {
        flex-grow: 1;
        flex-shrink: 1;
        font-size: 0.8em;
      }
      #{$member}__links {
        text-align: right;
        padding: 0;
        margin: 0;
        flex-grow: 0;
        flex-shrink: 0;
        list-style: none;
        li {
          padding: 0;
          margin: 0 0 0 10px;
          display: inline-block;
          font-size: 1.8em;
        }
      }
    }
    &:hover {
      z-index: 1;
      #{$member}__img {
        &:before {
          background-color: rgba(0, 0, 0, 0.3);
        }
      }
      #{$member}__tile {
        display: none;
      }
      #{$member}__details {
        display: block;
      }
      #{$member}__title {
        opacity: 1;
      }
      #{$member}__info {
        opacity: 1;
        width: 200%;
        padding-right: 40px;
        padding-left: 30px;
      }
      @include media-breakpoint-up(lg) {
        &.lg-1 {
          transform: translateX(-100%);
        }
        &.lg-2 {
          transform: translateX(-200%);
        }
      }
      @include media-breakpoint-down(md) {
        #{$member}__info {
          width: 100%;
          left: 100%;
          padding: 15px;
        }
        &.sm-1 {
          transform: translateX(0);
          #{$member}__info {
            left: -100%;
          }
        }
      }
    }
    @include media-breakpoint-down(md) {
      #{$member}__info {
        left: 100%;
      }
      &.sm-1 {
        #{$member}__info {
          left: 0%;
        }
      }
    }
  }
}

$post-card-padding: 30px;

.blog-nav {
  &__container {
    display: flex;
    justify-content: space-evenly;
  }
  &__link {
    a {
      color: black;
    }
    a:hover {
      color: $primary;
    }
  }
  &__active {
    a {
      color: $primary;
    }
  }
}

.blog-posts {
  list-style: none;
  margin: 0 0 5rem;
  padding: 0;

  &__post {
    width: 50%;
    padding: 15px;
  }
  &__post-card {
    background-color: #fff;
    padding: $post-card-padding;
    border-radius: 1px;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.14);
  }
  &__post-img {
    max-height: 350px;
    min-height: 350px;
    overflow: hidden;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    margin: ($post-card-padding * -1) ($post-card-padding * -1) 0 ($post-card-padding * -1);
  }
  &__post-title {
    margin: $post-card-padding 0;
  }
  &__post-preview {
    h3 {
      font-size: 1.2em;
    }
  }
  @include media-breakpoint-down(sm) {
    &__post {
      width: 100%;
    }
    &__post-title {
      font-size: 1.8rem;
    }
  }
}

.blog-post {
  background-color: #fff;
  padding: $post-card-padding;
  border-radius: 1px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.14);
  margin-bottom: 5em;
  margin-top: 1em;
  &__title {
    text-align: center;
    margin: 1em 0;
  }
  &__meta {
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    margin-bottom: 2em;
  }
  &__author {
    font-size: 16px;
    display: inline-block;
    margin-right: 2em;
    color: #888;
  }
  &__author-img {
    width: 30px;
    border-radius: 50%;
    height: 30px;
    display: inline-block;
    margin-right: 1em;
  }
  &__date {
    font-size: 14px;
    display: inline-block;
    font-weight: 300;
    color: #888;
  }
  &__body {
    font-size: 1em;
  }
}

/** Contact Us */
.page-contact {
  margin-bottom: 80px;
  &__title {
    h2 {
      font-size: 40px;
    }
    p {
      font-size: 18px;
      font-family: 'Open Sans', Arial, Helvetica, sans-serif;
      margin-bottom: 30px;
    }
  }
  &__form {
    label {
      display: none;
    }
    .form-control {
      border: 1px solid #eee;
      border-radius: 0;
      font-size: 18px;
      font-family: 'Open Sans', Arial, Helvetica, sans-serif;
      background-color: #f9f9f9;
      height: 38px;
      &:focus {
        box-shadow: none;
      }
    }
    textarea.form-control {
      height: 260px;
    }
    .btn-primary {
      background-color: #333;
      font-family: 'Open Sans', Arial, Helvetica, sans-serif;
      width: 260px;
      height: 57px;
      border: none;
      border-radius: 0;
      &:hover {
        color: #fff;
      }
      &:not(:disabled):not(.disabled):active {
        background-color: #333;
      }
      @include media-breakpoint-down(sm) {
        display: block;
        width: 100%;
      }
    }
  }
}

/** About Us */
.page-aboutus {
  p {
    font-size: 18px;
    font-family: 'Open Sans', Arial, Helvetica, sans-serif;
  }
  .team__list {
    .member {
      padding-bottom: 30px;
      position: relative;
      &__img {
        img {
          max-width: 100%;
        }
      }
      &__name {
        font-size: 23px;
        margin-top: 20px;
      }
      &__title {
        font-size: 18px;
        color: $primary;
        margin-top: 10px;
        margin-bottom: 10px;
      }
      p {
        font-size: 14px;
        font-family: 'Open Sans', Arial, Helvetica, sans-serif;
      }
      &__footer {
        position: absolute;
        bottom: 0;
        left: 15px;
        a {
          color: #333;
          font-size: 24px;
        }
      }
    }
  }
}

.uniform-btn {
  padding: 0;
  width: 200px;
}

.selectWrapper {
  overflow: hidden;
  border: 5px solid $primary;
  border-radius: 1rem;
  height: calc(1.5em + 0.75rem + 10px);
}

.selectBox {
  border: 0px;
  outline: none;
  height: 100%;
  display: block;
  box-sizing: border-box;
  margin: 0;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23000000%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E');
  background-repeat: no-repeat, repeat;
  background-position: right 0.7em top 50%, 0 0;
  background-size: 0.65em auto, 100%;
}

.selectBox::-ms-expand {
  display: none;
}

.alternate-form-field {
  color: white;
  background-color: $primary;
}

.inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

//Plans modal override to keep in line with the in-app plans modal:

.plan-item {
  font-size: 14px;
}

//Signup form:

.form-group {
  &__subtitle {
    font-size: 14px;
  }
}

// New landing page
.page-landing {
  text-align: center;
  &__logo {
    margin: 60px;
    img {
      width: 90px;
    }
  }
  &__title {
    color: #1cb5af;
    font-size: 32px;
    font-weight: 600;
  }
  &__description {
    font-size: 24px;
    max-width: 600px;
    margin: 30px auto;
  }
  &__actions {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    .btn {
      margin: 6px;
      font-size: 14px;
      font-weight: 600;
      background-color: #1cb5af;
      color: #fff;
      border-radius: 4px;
      height: 40px;
      line-height: 1;
      padding: 10px 25px;
      display: inline-flex;
      align-items: center;
    }
  }
}
.landing-items {
  margin-top: 70px;
  margin-left: 0;
  margin-right: 0;
  &__left {
    background-color: #f8dfea;
    padding-bottom: 50px;
    .landing-items__logo {
      margin-top: 50px;
      margin-bottom: 20px;
      img {
        height: 46px;
      }
    }
    .btn {
      font-size: 14px;
      font-weight: 600;
      background-color: #cc215d;
      color: #fff;
      border-radius: 4px;
      height: 40px;
      line-height: 1;
      padding: 10px 25px;
      display: inline-flex;
      align-items: center;
    }
  }
  &__right {
    background-color: #f7f7f7;
    padding-bottom: 50px;
    .landing-items__logo {
      margin-top: 58px;
      margin-bottom: 28px;
      img {
        height: 30px;
      }
    }
    .btn {
      font-size: 14px;
      font-weight: 600;
      background-color: #5c5cb9;
      color: #fff;
      border-radius: 4px;
      height: 40px;
      line-height: 1;
      padding: 10px 25px;
      display: inline-flex;
      align-items: center;
    }
  }
  &__links {
    img {
      height: 40px;
      display: inline-block;
      margin: 0 5px;
    }
  }
  &__label {
    letter-spacing: 0.72px;
    font-size: 12px;
    font-weight: 600;
  }
  &__description {
    font-size: 14px;
    max-width: 360px;
    margin: 20px auto;
  }
  &__img {
    height: 230px;
    display: inline-block;
    margin-top: 30px;
  }
}
.site-top-banner {
  background-color: #c5f2ee;
  text-align: center;
  font-size: 14px;
  line-height: 20px;
  padding: 10px;
  a {
    text-decoration: underline;
  }
}
