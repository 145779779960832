.loader {
  margin: 0 auto;
  background-image: url("https://media.q-83.com/site/q-83_q.svg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  -webkit-animation: reveal 1s infinite ease-out alternate-reverse;
  animation: reveal 1s infinite ease-out alternate-reverse;
  width: 100px;
  height: 100px;
}
@keyframes reveal {
  0% {
    clip-path: polygon(50% 50%, 70% 100%, 25% 100%);
  }
  5% {
    clip-path: polygon(50% 50%, 70% 100%, 0% 100%);
  }
  10% {
    clip-path: polygon(50% 50%, 70% 100%, 0% 100%, 0% 75%);
  }
  15% {
    clip-path: polygon(50% 50%, 70% 100%, 0% 100%, 0% 50%);
  }
  20% {
    clip-path: polygon(50% 50%, 70% 100%, 0% 100%, 0% 25%);
  }
  25% {
    clip-path: polygon(50% 50%, 70% 100%, 0% 100%, 0% 0%);
  }
  30% {
    clip-path: polygon(50% 50%, 70% 100%, 0% 100%, 0% 0%, 25% 0%);
  }
  35% {
    clip-path: polygon(50% 50%, 70% 100%, 0% 100%, 0% 0%, 50% 0%);
  }
  40% {
    clip-path: polygon(50% 50%, 70% 100%, 0% 100%, 0% 0%, 75% 0%);
  }
  45% {
    clip-path: polygon(50% 50%, 70% 100%, 0% 100%, 0% 0%, 100% 0%);
  }
  50% {
    clip-path: polygon(50% 50%, 70% 100%, 0% 100%, 0% 0%, 100% 0%, 100% 25%);
  }
  55% {
    clip-path: polygon(50% 50%, 70% 100%, 0% 100%, 0% 0%, 100% 0%, 100% 50%);
  }
  60% {
    clip-path: polygon(50% 50%, 70% 100%, 0% 100%, 0% 0%, 100% 0%, 100% 75%);
  }
  65% {
    clip-path: polygon(50% 50%, 70% 100%, 0% 100%, 0% 0%, 100% 0%, 100% 100%);
  }
  70% {
    clip-path: polygon(
      50% 50%,
      70% 100%,
      0% 100%,
      0% 0%,
      100% 0%,
      100% 100%,
      75% 100%
    );
  }
  75% {
    clip-path: polygon(
      50% 50%,
      70% 100%,
      0% 100%,
      0% 0%,
      100% 0%,
      100% 100%,
      50% 100%
    );
  }
}
